import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'reactstrap';

import './OrganizationClassification.css';

const OrganizationClassification = ({
  organization_id,
  upsertOrganization,
  type,
  primary_objective,
  spend_size,
  monthly_spend_segment,
  selectNextUser,
  classification_user_id,
}) => {
  const getSpendSize = () => {
    let spendSize;
    switch (true) {
      case monthly_spend_segment < 5:
        spendSize = '1';
        break;
      case (monthly_spend_segment >= 5 && monthly_spend_segment < 10):
        spendSize = '2';
        break;
      case (monthly_spend_segment >= 10 && monthly_spend_segment < 25):
        spendSize = '3';
        break;
      case (monthly_spend_segment >= 25 && monthly_spend_segment < 100):
        spendSize = '4';
        break;
      case (monthly_spend_segment >= 100 && monthly_spend_segment < 250):
        spendSize = '5';
        break;
      case (monthly_spend_segment >= 250):
        spendSize = '6';
        break;
      default:
        spendSize = '1';
    }
    return spendSize;
  };

  const [formType, setFormType] = useState(type);
  const [formPrimaryObjective, setFormPrimaryObjective] = useState(primary_objective);
  const [formSpendSize, setFormSpendSize] = useState(spend_size || getSpendSize());
  const [orgId, setOrgId] = useState(null);

  useEffect(() => {
    if (organization_id !== orgId) {
      setFormType(type);
      setFormPrimaryObjective(primary_objective || '');
      setFormSpendSize(spend_size || getSpendSize());
      setOrgId(organization_id || '');
    }
  });

  const handleSave = () => {
    const org = {
      organization_id,
      type: 'billing_group',
      classification: {
        type: formType,
        primary_objective: formPrimaryObjective,
        spend_size: formSpendSize,
        user_id: localStorage.getItem('user_id'),
      },
    };

    upsertOrganization(org)
      .then(() => selectNextUser());
  };
  return <div>
    <div>
      <label className="text-muted mb-0" htmlFor="formType">Org Type</label>
      <Input
        type="select"
        name="select"
        id="formType"
        value={formType}
        onChange={e => setFormType(e.target.value)}
      >
        <option value="">-- Select Organization Type --</option>
        <option value="agency">Agency: Provide marketing execution services</option>
        <option value="smb">SMB: Sole proprietor to mid size company</option>
        <option value="enterprise">Enterprise: Global 2000 or household brand</option>
        <option value="consultant">Consultant: Provide training and/or courseware</option>
        <option value="affiliate">Affiliate Marketer: Commissions on sales or leads</option>
        <option value="ignore">Ignore: Unable to Classify</option>
      </Input>
    </div>
    <div>
      <label className="text-muted mt-1 mb-0" htmlFor="formPrimaryObjective">Primary Objective</label>
      <Input
        type="select"
        name="select"
        id="formPrimaryObjective"
        value={formPrimaryObjective}
        onChange={e => setFormPrimaryObjective(e.target.value)}
      >
        <option value="">-- Select Primary Objective --</option>
        <option value="Ecommerce">Ecommerce: Direct online sales of physical goods</option>
        <option value="Lead Gen">Lead Gen: Lead generation for services; lead form is conversion</option>
        <option value="Subscription">Subscription: Direct sale of recurring goods or services</option>
        <option value="Awareness">Awareness: Drive brand and/or product awareness</option>
        <option value="App Install">App Install: Installation of app on mobile or desktop device</option>
        <option value="Multi">Multi: Significant amount of more than one of the above</option>
      </Input>
    </div>
    <div>
      <label className="text-muted mt-1 mb-0" htmlFor="formSpendSize">Spend Size</label>
      <Input
        type="select"
        name="select"
        id="formSpendSize"
        value={formSpendSize}
        onChange={e => setFormSpendSize(e.target.value)}
      >
        <option value="">-- Select Spend Size --</option>
        <option value="1">XS: Less than $5k</option>
        <option value="2">S: $5k to $10k</option>
        <option value="3">M: $10k to $25k</option>
        <option value="4">L: $25k to $100k</option>
        <option value="5">XL: $100k to $250k</option>
        <option value="6">2XL: Greater than $250k</option>
      </Input>
    </div>
    <div className="d-flex justify-content-between">
      {classification_user_id && <div className="my-auto small">
        Classified by:
        {' '}
        {classification_user_id}
      </div>}
      <Button
        className="ml-auto mt-2"
        onClick={handleSave}
      >
Save
      </Button>
    </div>
  </div>;
};

OrganizationClassification.defaultProps = {
  type: '',
  primary_objective: '',
  spend_size: '',
  monthly_spend_segment: 0,
  classification_user_id: '',
};

OrganizationClassification.propTypes = {
  user: PropTypes.any, //eslint-disable-line
  organization_id: PropTypes.number.isRequired,
  type: PropTypes.string,
  primary_objective: PropTypes.string,
  spend_size: PropTypes.string,
  monthly_spend_segment: PropTypes.number,
  upsertOrganization: PropTypes.func.isRequired,
  selectNextUser: PropTypes.func.isRequired,
  classification_user_id: PropTypes.string,
};

export default OrganizationClassification;
