import React from 'react';
import {
  Card, CardTitle, CardBody, Row, Col,
} from 'reactstrap';
import AdPreviewContainer from '../../containers/AdPreview/AdPreviewContainer';
import AdAccountClassificationContainer from '../../containers/Customer/AdAccountClassificationContainer';

const getAgeString = (age) => {
  let ageString = '';
  let accountAge = age;
  // Map lengths of `diff` to different time periods
  const values = [[' year', 365], [' month', 30], [' day', 1]];

  // Iterate over the values...
  for (let i = 0; i < values.length; i += 1) {
    const amount = Math.floor(accountAge / values[i][1]);

    // ... and find the largest time value that fits into the diff
    if (amount >= 1) {
      // If we match, add to the string ('s' is for pluralization)
      ageString += `${amount + values[i][0] + (amount > 1 ? 's' : '')} `;

      // and subtract from the diff
      accountAge -= amount * values[i][1];
    }
  }
  return ageString;
};

const AdAccount = ({ ad_account, fb_user_id, access_token }) => <Card>
  <CardTitle className="text-center mt-3">
    <h4 className="ml-3">
      {ad_account.name}
      {' '}
- (
      {ad_account.account_id}
)
    </h4>
  </CardTitle>
  <CardBody>
    <div className="container-fluid">
      <Row>
        <Col xs="5">
          <div className="font-weight-bold">{ad_account.business_name}</div>
          <span>
            <div className="font-weight-bold d-inline">Monthly Spend Segment: </div>
            <div className="d-inline">{ad_account.monthly_spend_segment}</div>
          </span>
          <br />
          <span>
            <div className="font-weight-bold d-inline">Account Age: </div>
            <div className="d-inline">{getAgeString(ad_account.age)}</div>
          </span>
          <br />
          <span>
            <div className="font-weight-bold d-inline">Business Vertical: </div>
            <div className="d-inline">{ad_account.business_vertical}</div>
          </span>
          <br />
          <span>
            <div className="font-weight-bold d-inline">Business Category: </div>
            <div className="d-inline">{ad_account.business_category}</div>
          </span>
          <div className="border-dark my-2 p-3" style={{ backgroundColor: '#eee' }}>
            <div className="ml-auto mr-auto"><u>ACCOUNT OBJECTIVES</u></div>
            <table className="w-100">
              <tbody>
                {ad_account.objective_counts && ad_account.objective_counts.map(objective_count => <tr key={objective_count.objective_name}>
                  <td>{objective_count.objective_name}</td>
                  <td>{objective_count.objective_count}</td>
                </tr>)}
              </tbody>
            </table>
          </div>
          <AdAccountClassificationContainer
            account_id={ad_account.account_id}
            classification_category={ad_account.classification_category}
            classification_consideration={ad_account.classification_consideration}
            classification_business_model={ad_account.classification_business_model}
            classification_tags={ad_account.classification_tags}
            />
        </Col>
        <Col xs="7">
          <AdPreviewContainer
            fb_user_id={fb_user_id.replace('facebook|', '')}
            account_id={ad_account.account_id}
            access_token={access_token}
          />
        </Col>
      </Row>
    </div>
  </CardBody>
</Card>;

export default AdAccount;
