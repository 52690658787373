import { graphql } from 'react-apollo/index';
import gql from 'graphql-tag';

import AdAccountClassification from '../../components/Customer/AdAccountClassification';

const AD_ACCOUNT_CLASSIFICATION_TAGS = gql`
query AdAccountClassificationTags {
  ad_account_classification_tags {
    classification_category
    classification_tag
  }
}`;

const withTags = graphql(
  AD_ACCOUNT_CLASSIFICATION_TAGS,
  {
    props: ({ data: { loading, ad_account_classification_tags }}) => {
      return {
        loading,
        ad_account_classification_tags,
      };
    },
  },
)(AdAccountClassification);

const UPDATE_ACCOUNT_CLASSIFICATIONS = gql`
    mutation UpdateAccountClassifications($ad_account: AdAccountClassificationInput!) {
        updateAccountClassifications(ad_account: $ad_account) {
            account_id
        }
    }  
`;

const ClassifyAccountContainer = graphql(
  UPDATE_ACCOUNT_CLASSIFICATIONS,
  {
    props: ({ mutate }) => ({
      updateAccountClassifications: (ad_account) => {
        return mutate({
          variables: {
            ad_account,
          },
          refetchQueries: ['get_user_by_user_id'],
        });
      },
    }),
  },
)(withTags);

export default ClassifyAccountContainer;
