import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import AdPreview from '../../components/AdPreview/AdPreview';

const AD_PREVIEW_MOST_SPEND = gql`
    query AdPreviewMostSpend($fb_user_id: String, $account_id: String, $access_token: String) {
        ad_preview_most_spend(fb_user_id: $fb_user_id, account_id: $account_id, access_token: $access_token) {
            id
            iframe
            cta_url
        }
    }
`;

const AdPreviewContainer = graphql(
  AD_PREVIEW_MOST_SPEND,
  {
    skip: ({ fb_user_id }) => !fb_user_id,
    options: ({ fb_user_id, account_id, access_token }) => ({
      variables: {
        fb_user_id,
        account_id,
        access_token,
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'none',
    }),
    props: ({ data: { loading, ad_preview_most_spend, error } }) => ({
      loadingAdPreview: loading,
      ad_preview_most_spend,
      error,
    }),
  },
)(AdPreview);

export default AdPreviewContainer;
