import React, { Fragment, useState, useEffect } from 'react';
import { Router, Route } from 'react-router-dom';
import Auth from './utils/Auth';
import history from './history';

import Header from './components/Header/Header';
import Callback from './components/Callback/Callback';
import LayoutContainer from './containers/Layout/LayoutContainer';

const auth = new Auth();

const handleAuthentication = ({ location }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
};

const App = () => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    if (!isMounted && localStorage.getItem('isLoggedIn') === 'true') {
      auth.renewSession();
      setIsMounted(true);
    }
  });

  return (<Router history={history}>
  <Fragment>
    <Route path="/" render={props => <Header auth={auth} {...props} />} />
    <Route path="/" render={() => <LayoutContainer isAuthenticated={auth.isAuthenticated()} />} />
    <Route
      path="/callback"
      render={(props) => {
        handleAuthentication(props);
        return <Callback {...props} />;
      }}
    />
  </Fragment>
</Router>
)};

export default App;
