import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CustomerList from '../CustomerList/CustomerList';
import Customer from '../../containers/Customer/CustomerContainer';

const Layout = ({ isAuthenticated, all_users }) => {
  const [user_id, setUserId] = useState(null);
  const [userIndex, setUserIndex] = useState(0);
  const [filter, setFilter] = useState(null);
  const [classifiedOrganizations, setClassifiedOrganizations] = useState(null);
  const [unclassifiedOrganizations, setUnClassifiedOrganizations] = useState(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (all_users) {
      const classified = _.filter(all_users,
        user => user.organizations && user.organizations[0].organization.classification);
      const unclassified = _.filter(all_users,
        user => user.organizations && !user.organizations[0].organization.classification);
      if (!isMounted
        || classified.length !== classifiedOrganizations.length
        || unclassified.length !== unclassifiedOrganizations.length) {
        setClassifiedOrganizations(classified);
        setUnClassifiedOrganizations(unclassified);
        if (!filter) setFilter(unclassified.length > 0 ? 'unclassified' : 'classified');
        setIsMounted(true);
      }
    }
  });

  if (!all_users || !unclassifiedOrganizations || !classifiedOrganizations) return <div />;

  const filteredUsers = filter === 'unclassified' ? unclassifiedOrganizations : classifiedOrganizations;

  if (!isAuthenticated) return <div />;

  const selectNextUser = () => {
    const nextUserIndex = userIndex + 1;
    const nextUser = filteredUsers[nextUserIndex];
    setUserId(filteredUsers[nextUser ? nextUser.user_id : null]);
    setUserIndex(nextUserIndex);
  };

  return <div className="container-fluid mt-3 p-3">
    <div className="row">
      <div className="mr-0" style={{ width: '200px' }}>
        <CustomerList
          filteredUsers={filteredUsers}
          setUserId={setUserId}
          userIndex={userIndex}
          setUserIndex={setUserIndex}
          unclassifiedCount={unclassifiedOrganizations.length}
          classifiedCount={classifiedOrganizations.length}
          filter={filter}
          setFilter={setFilter}
        />
      </div>
      <div className="col ml-0">
        <Customer
          user_id={user_id}
          selectNextUser={selectNextUser}
        />
      </div>
    </div>
  </div>;
};

Layout.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired, // eslint-disable-line
};

export default Layout;
