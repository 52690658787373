import React from 'react';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import './CustomerList.css';

const CustomerList = ({
  filteredUsers,
  unclassifiedCount,
  classifiedCount,
  setUserId,
  userIndex,
  setUserIndex,
  setFilter,
  filter,
}) => <div>
  <div className="d-inline-flex justify-content-around">
    <Button
      color="link"
      active={filter === 'unclassified'}
      disabled={filter === 'unclassified'}
      onClick={() => setFilter('unclassified')}
      className="m-0 p-0"
      style={{ fontSize: '0.75em' }}
    >
To Do
    </Button>
    <small>{`(${unclassifiedCount})`}</small>
    <Button
      color="link"
      active={filter === 'classified'}
      disabled={filter === 'classified'}
      onClick={() => setFilter('classified')}
      className="m-0 ml-3 p-0"
      style={{ fontSize: '0.75em' }}
    >
Done
    </Button>
    <small>{`(${classifiedCount})`}</small>
  </div>
  <div className="text-left mt-2" style={{ overflowY: 'scroll', height: '800px' }}>
    {filteredUsers && filteredUsers.map((user, index) => <div
      key={user.user_id}
      className={classnames({ activecustomer: userIndex === index })}
    >
      <Button
        color="link"
        onClick={() => {
          setUserIndex(index);
          setUserId(user.user_id);
        }}
      >
        {user.first_name}
        {' '}
        {user.last_name}
      </Button>
    </div>)}
  </div>
</div>;

export default CustomerList;
