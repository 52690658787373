import React from 'react';
import Spinner from 'react-spinkit';
import { Alert } from 'reactstrap';

const AdPreview = ({ ad_preview_most_spend, loadingAdPreview, error }) => {
  const getAdPreview = (iframe) => {
    const cleanIframe = iframe.replace('\\', '');
    return { __html: cleanIframe };
  };

  if (loadingAdPreview) {
    return <div className="text-center">
    Loading Ad Preview...
      <Spinner
        fadeIn="none"
        name="wave"
        style={{
          marginRight: 'auto',
          marginLeft: 'auto',
          display: 'block',
        }}
      />
    </div>;
  }

  if (error) {
    return <Alert color="danger">
      <div>Error retrieving ad preview.</div>
      <small>{error.graphQLErrors[0].message}</small>
    </Alert>;
  }

  return <div>
    {ad_preview_most_spend
      ? <div>
        {ad_preview_most_spend.cta_url
        && <p>
Landing page URL:
          <a href={ad_preview_most_spend.cta_url} target="_new">{ad_preview_most_spend.cta_url}</a>
        </p>}
        <div dangerouslySetInnerHTML={getAdPreview(ad_preview_most_spend.iframe)} />
        </div>
      : <div>Ad Preview Unavailable</div>}
  </div>;
};

export default AdPreview;
