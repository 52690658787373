import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Layout from '../../components/Layout/Layout';

const GET_ALL_AD_ACCOUNTS = gql`
    query all_users {
        all_users {
            id
            user_id
            first_name
            last_name
            organizations {
                organization {
                    id
                    organization_id
                    classification
                }
            }
        }
    }
`;

const LayoutContainer = graphql(
  GET_ALL_AD_ACCOUNTS,
  {
    fetchPolicy: 'network-only',
    props: ({ data: { loading, all_users } }) => ({
      loading,
      all_users,
    }),
  },
)(Layout);

export default LayoutContainer;
