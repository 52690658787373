import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import OrganizationClassification from '../../components/Customer/OrganizationClassification';

const UPSERT_ORGANIZATION = gql`
mutation upsertOrganization($org: OrganizationInput!) {
    upsertOrganization(org: $org) {
        id
        classification
  }
}`;

const OrganizationClassificationContainer = graphql(
  UPSERT_ORGANIZATION,
  {
    props: ({ mutate }) => ({
      upsertOrganization: org => mutate({
        variables: { org },
        refetchQueries: ['all_users'],
      }),
    }),
  },
)(OrganizationClassification);

export default OrganizationClassificationContainer;
