import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ListGroup, ListGroupItem, Button, Nav, NavItem, NavLink,
} from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import ScrollArea from 'react-scrollbar';
import classnames from 'classnames';
import Spinner from 'react-spinkit';
import OrganizationClassificationContainer from '../../containers/Customer/OrganizationClassificationContainer';
import AdAccount from './AdAccount';


const Customer = ({ user, selectNextUser, userLoading }) => {
  const [selectedAdAccount, setSelectedAdAccount] = useState(null);
  const [activeAdAccounts, setActiveAdAccounts] = useState(0);
  const [otherAdAccounts, setOtherAdAccounts] = useState(0);
  const [displayActive, setDisplayActive] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (user && user.user_id !== userId) {
      const active = _.filter(user.ad_accounts, ad_account => ['active', 'trialing'].includes(ad_account.subscription_status));
      setActiveAdAccounts(active);
      const other = _.filter(user.ad_accounts, ad_account => !['active', 'trialing'].includes(ad_account.subscription_status))
      setOtherAdAccounts(other);
      setDisplayActive(active.length > 0);
      setUserId(user.user_id);
      setSelectedAdAccount(active.length
        ? active[0] : other && other[0]);
    }
  });

  const userMatch = user
    && selectedAdAccount
    && _.find(user.ad_accounts, { account_id: selectedAdAccount.account_id });

  if (userLoading) {
    return <Spinner
      fadeIn="none"
      name="wave"
      style={{
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'block',
      }}
    />;
  }

  if (!user || !userMatch) {
    return <div />;
  }

  const { organizations: [{ organization }] } = user;
  const { classification = {} } = organization;
  const { type, primary_objective, spend_size, user_id } = classification || { type: '', primary_objective: '', spend_size: '' };
  const { accounts_summary: { monthly_spend_segment } } = user;

  let filteredAdAccounts;
  if (displayActive) {
    filteredAdAccounts = activeAdAccounts;
  } else {
    filteredAdAccounts = otherAdAccounts;
  }

  return <div className="container-fluid mt-2 border-light">
    <div className="row">
      <div className="col-5">
          <div
            className="d-flex justify-content-between"
            style={{ backgroundColor: '#eee', border: '1px solid #ddd', padding: '3px' }}
          >
            <div className="text-center d-flex justify-content-around my-0">
              <img
                className="my-auto"
                src={`https://graph.facebook.com/${user.user_id.replace('facebook|', '')}/picture?type=small`}
                alt="Profile"
              />
              <div
                className="my-auto ml-2 text-left"
              >
                <a
                  href={`https://dashboard.stripe.com/customers/${organization.billing_system_id}`}
                  target="_new"
                >{user.first_name}
                {' '}
                {user.last_name}
                </a>
                <div>{user.user_id.replace('facebook|', '')}</div>
              </div>
            </div>
            <div className="my-auto">
              {organization.billing_plan}
            </div>
            <div className="my-auto">
              {organization.subscription_status}
            </div>
            <div className="my-auto">
              {moment(new Date(organization.subscription_start_date)).format('M/DD/YY')}
            </div>
            <div className="my-auto mr-2">
              {monthly_spend_segment}k
            </div>
          </div>
          <OrganizationClassificationContainer
            organization_id={organization.organization_id}
            type={type}
            primary_objective={primary_objective}
            spend_size={spend_size}
            monthly_spend_segment={monthly_spend_segment}
            selectNextUser={selectNextUser}
            classification_user_id={user_id}
          />
        <Nav tabs className="mt-2 mb-0">
          <NavItem>
            <NavLink
              className={classnames({ active: !!displayActive })}
              onClick={() => {
                setSelectedAdAccount(activeAdAccounts[0]);
                setDisplayActive(true);
              }}
            >
              Active ({activeAdAccounts.length})
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: !displayActive })}
              onClick={() => {
                setSelectedAdAccount(otherAdAccounts[0]);
                setDisplayActive(false);
              }}
            >
              Other ({otherAdAccounts.length})
            </NavLink>
          </NavItem>
        </Nav>
        <ListGroup className="mt-2">
          <ScrollArea
            speed={0.8}
            className="area"
            horizontal={false}
          >
          {filteredAdAccounts
          && filteredAdAccounts.map(ad_account => <ListGroupItem
            className="d-flex justify-content-between"
            key={ad_account.account_id}
          >
            <Button
              color="link"
              onClick={() => setSelectedAdAccount(ad_account)}
            >
              {ad_account.name}
            </Button>
            <div className="my-auto">
              {ad_account.monthly_spend_segment}
              k
            </div>
          </ListGroupItem>)}
          </ScrollArea>
        </ListGroup>
      </div>
      <div className="col">
        {selectedAdAccount && <AdAccount
          ad_account={selectedAdAccount}
          fb_user_id={user.user_id}
          access_token={user.facebook_access_token}
        />}
      </div>
    </div>
  </div>;
};

Customer.propTypes = {
  user: PropTypes.any, //eslint-disable-line
};

export default Customer;
