import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { withClientState } from 'apollo-link-state';
import { ApolloLink, Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';

const cache = new InMemoryCache();

const request = async (operation) => {
  const token = await localStorage.getItem('idToken');
  operation.setContext({
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

const requestLink = new ApolloLink((operation, forward) => new Observable((observer) => {
  let handle;
  Promise.resolve(operation)
    .then(oper => request(oper))
    .then(() => {
      operation.setContext({
        credentials: 'same-origin',
      });
      handle = forward(operation).subscribe({
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      });
    })
    .catch(observer.error.bind(observer));

  return () => {
    if (handle) handle.unsubscribe();
  };
}));

export const errorLink = onError(({ graphQLErrors }) => {
  /*
  onError receives a callback in the event a GraphQL or network error occurs.
  This example is a bit contrived, but in the real world, you could connect
  a logging service to the errorLink or perform a specific action in response
  to an error.
  */

  if (graphQLErrors) {
    graphQLErrors.map(({ message, location, path }) => {
      return console.error(
        `[GraphQL error]: Message: ${message}, Location: ${location}, Path: ${
          path
        }`,
      );
    });
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    requestLink,
    withClientState({
      defaults: {
        isConnected: true,
      },
      resolvers: {
        Mutation: {
          updateNetworkStatus: (_, { isConnected }, { cache }) => {
            cache.writeData({ data: { isConnected } });
            return null;
          },
        },
      },
      cache,
    }),
    new HttpLink({
      uri: `https://${process.env.REACT_APP_API_HOST}/graphql`,
      credentials: 'include',
    }),
  ]),
  cache,
});

export default client;
