import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Button, FormGroup, Label,
} from 'reactstrap';
import TagsInput from 'react-tagsinput';
import Autosuggest from 'react-autosuggest';

import 'react-tagsinput/react-tagsinput.css';

const AdAccountClassification = ({
  account_id,
  updateAccountClassifications,
  classification_category,
  classification_consideration,
  classification_business_model,
  classification_tags,
  ad_account_classification_tags,
}) => {
  const [stateAccountId, setStateAccountId] = useState(account_id);
  const [formClassificationCategory,
    setFormClassificationCategory] = useState(classification_category || '');
  const [formClassificationConsideration,
    setFormClassificationConsideration] = useState(classification_consideration || '');
  const [formClassificationBusinessModel,
    setFormClassificationBusinessModel] = useState(classification_business_model || '');
  const [formClassificationTags,
    setFormClassificationTags] = useState(classification_tags || []);

  useEffect(() => {
    if (account_id !== stateAccountId) {
      setFormClassificationCategory(classification_category || '');
      setFormClassificationConsideration(classification_consideration || '');
      setFormClassificationBusinessModel(classification_business_model || '');
      setFormClassificationTags(classification_tags || []);
      setStateAccountId(account_id);
    }
  });

  const autocompleteRenderInput = ({ addTag, value, ref, onChange, ...props }) => {
    const handleOnChange = (e, { method }) => {
      if (method === 'enter') {
        e.preventDefault();
      } else {
        onChange(e);
      }
    };

    const inputValue = (value && value.trim().toLowerCase()) || '';
    const inputLength = inputValue.length;

    const suggestions = ad_account_classification_tags
      && ad_account_classification_tags
        .filter(ad_account_classification_tag => classification_category === ad_account_classification_tag.classification_category
          && ad_account_classification_tag.classification_tag
            .toLowerCase().slice(0, inputLength) === inputValue);

    return (
      <Autosuggest
        ref={ref}
        suggestions={suggestions}
        shouldRenderSuggestions={value => value && value.trim().length > 0}
        getSuggestionValue={suggestion => suggestion.classification_tag}
        renderSuggestion={suggestion => <span>{suggestion.classification_tag}</span>}
        inputProps={{ ...props, value, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.classification_tag);
        }}
        onSuggestionsClearRequested={() => {}}
        onSuggestionsFetchRequested={() => {}}
      />
    );
  };

  const handleSave = () => {
    const ad_account = {
      account_id,
      classification_category: formClassificationCategory,
      classification_consideration: formClassificationConsideration,
      classification_business_model: formClassificationBusinessModel,
      classification_tags: formClassificationTags,
    };
    updateAccountClassifications(ad_account);
  };

  return <div>
    <span>
      <FormGroup>
        <Label for="classificationCategory" className="font-weight-bold d-inline">Classification Category:</Label>
        <Input
          type="select"
          name="classificationCategory"
          id="classificationCategory"
          className="d-inline"
          value={formClassificationCategory}
          onChange={e => setFormClassificationCategory(e.target.value)}
        >
          <option value="">-- Select a Category --</option>
          <option value="Goods">Goods</option>
          <option value="Services">Services</option>
          <option value="Local">Local</option>
          <option value="Traffic & Engagement">Traffic & Engagement</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label className="font-weight-bold d-inline">Tags:</Label>
        <TagsInput
          value={formClassificationTags}
          onChange={tags => setFormClassificationTags(tags)}
          renderInput={autocompleteRenderInput.bind(this)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="classificationConsideration" className="font-weight-bold d-inline">Consideration Level:</Label>
        <Input
          type="select"
          name="classificationConsideration"
          id="classificationConsideration"
          className="d-inline"
          value={formClassificationConsideration}
          onChange={e => setFormClassificationConsideration(e.target.value)}
        >
          <option value="">-- Select a Consideration Level --</option>
          <option>Low</option>
          <option>Medium</option>
          <option>High</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="classificationBusinessModel" className="font-weight-bold d-inline">Business Model:</Label>
        <Input
          type="select"
          name="classificationBusinessModel"
          id="classificationBusinessModel"
          className="d-inline"
          value={formClassificationBusinessModel}
          onChange={e => setFormClassificationBusinessModel(e.target.value)}
        >
          <option value="">-- Select a Business Model --</option>
          <option value="one time purchase">One-time purchase</option>
          <option value="repeat purchase LTV">Repeat purchase LTV</option>
          <option value="subscription">Subscription</option>
        </Input>
      </FormGroup>
    </span>
    <Button color="success" onClick={handleSave}>SAVE</Button>
  </div>;
};

AdAccountClassification.defaultProps = {
  classification_category: '',
  classification_consideration: '',
  classification_business_model: '',
  classification_tags: [],
  ad_account_classification_tags: [],
};

AdAccountClassification.propTypes = {
  account_id: PropTypes.string.isRequired,
  classification_category: PropTypes.string,
  classification_consideration: PropTypes.string,
  classification_business_model: PropTypes.string,
  classification_tags: PropTypes.any, //eslint-disable-line
  ad_account_classification_tags: PropTypes.any, //eslint-disable-line
  updateAccountClassifications: PropTypes.func.isRequired,
};

export default AdAccountClassification;
