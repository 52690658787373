import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Customer from '../../components/Customer/Customer';

const GET_USER_BY_USER_ID = gql`
    query get_user_by_user_id($user_id: String!) {
        get_user_by_user_id(user_id: $user_id) {
            id
            email
            user_id
            user_hash
            first_name
            last_name
            contact_id
            contact_first_name
            contact_last_name
            contact_company
            contact_job_title
            contact_email
            contact_phone_number
            contact_preference
            completed_tours
            legacy_plan_eligible
            features
            facebook_access_token            
            is_facebook_ready
            is_google_ready
            google_user_id
            organizations {
                is_billing_owner
                is_default
                organization {
                    id
                    organization_id
                    name
                    billing_system_id
                    billing_system_name
                    billing_plan
                    type
                    trial_count
                    subscription_start_date
                    subscription_status
                    created_at
                    classification
                    ad_accounts {
                        id
                        account_id
                        name
                        timezone_name
                        timezone_offset_hours_utc
                        role
                        subscription_status
                        currency
                        business_name
                        business_country
                        business_state
                        account_status
                        amount_spent
                        subscription_id
                        billing_system_name
                        age
                        monthly_spend_segment
                        amount_spent
                        current_period_spend
                    }
                }
            }
            ad_accounts {
                id
                account_id
                name
                age
                business_name
                monthly_spend_segment
                amount_spent
                account_status
                business_category
                business_vertical
                classification_category
                classification_tags
                classification_consideration
                classification_business_model   
                subscription_status
                objective_counts {
                    objective_name
                    objective_count
                }
            }
            accounts_summary {
                id
                monthly_spend_segment
                trialing_count
                active_count
                not_activated_count
                past_due_count
                pending_count
                canceled_count
                cltrack_count
                bidtest_count
                avg_ad_account_age
                budget_recommendations_made
                budget_recommendations_applied
                total_grade_count
                a_count
                b_count
                c_count
                d_count
                f_count
                u_count
                ng_count
            }
        }
    }

`;

const CustomerContainer = graphql(
  GET_USER_BY_USER_ID,
  {
    skip: ({ user_id }) => !user_id,
    props: ({ data: { loading, get_user_by_user_id } }) => ({
      userLoading: loading,
      user: get_user_by_user_id,
    }),
  },
)(Customer);

export default CustomerContainer;
