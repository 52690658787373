import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand, Button } from 'reactstrap';

const Header = ({
  auth: {
    isAuthenticated, login, logout,
  },
}) => {
  return <div>
    <Navbar style={{
      backgroundColor: '#000',
      height: '62px',
    }}>
      <NavbarBrand><img src="/images/logo.png" alt="logo" style={{ height: '36px' }} /></NavbarBrand>
      {
        !isAuthenticated() ? (<Button className="btn-margin" onClick={() => login()}>Log In</Button>)
          : (<Button className="btn-margin" onClick={() => logout()}>Log Out</Button>)
      }
    </Navbar>
  </div>;
};


Header.propTypes = {
  auth: PropTypes.any.isRequired, // eslint-disable-line
};

export default Header;
